.Progress {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--darkGray);
}

.Progress-fill {
  background: var(--red);
  transition: width 1s linear;
}
