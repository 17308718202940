@import url(https://fonts.googleapis.com/css?family=Space+Mono&display=swap);
.Progress {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--darkGray);
}

.Progress-fill {
  background: var(--red);
  -webkit-transition: width 1s linear;
  transition: width 1s linear;
}

.Number small {
  opacity: 0.5;
  font-size: inherit;
}

.Timer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Timer-clock {
  align-items: center;
  color: var(--lightGray);
  display: flex;
  flex-grow: 1;
  font-size: 12rem;
  overflow: hidden;
  max-height: 11rem;
}

.Timer-clock-colon {
  margin: 0 -1rem;
}

.Timer-label {
  color: var(--mediumGray);
  text-transform: uppercase;
  font-size: 2rem;
}

.App {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.App-progress {
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 0.5rem;
  left: 0;
  position: absolute;
  right: 0;
}

.App-toggleMuteButton {
  background: none;
  border: none;
  color: var(--mediumGray);
  cursor: pointer;
  display: flex;
  font-size: 4rem;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.App-toggleMuteButton:hover {
  opacity: 1;
}

.App-toggleMuteButton.is-muted {
  color: var(--red);
}

:root {
  --darkBlue: #222831;
  --lightGray: #eeeeee;
  --mediumGray: #bbbbbb;
  --red: #d65a31;
  --darkGray: #141414;
  --monospace: "Space Mono", monospace;
}

html {
  font-size: 2vw;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #222831;
  background-color: var(--darkBlue);
  margin: 0;
  padding: 0;
  font-family: "Space Mono", monospace;
  font-family: var(--monospace);
}

