.Timer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Timer-clock {
  align-items: center;
  color: var(--lightGray);
  display: flex;
  flex-grow: 1;
  font-size: 12rem;
  overflow: hidden;
  max-height: 11rem;
}

.Timer-clock-colon {
  margin: 0 -1rem;
}

.Timer-label {
  color: var(--mediumGray);
  text-transform: uppercase;
  font-size: 2rem;
}
