@import url("https://fonts.googleapis.com/css?family=Space+Mono&display=swap");

:root {
  --darkBlue: #222831;
  --lightGray: #eeeeee;
  --mediumGray: #bbbbbb;
  --red: #d65a31;
  --darkGray: #141414;
  --monospace: "Space Mono", monospace;
}

html {
  font-size: 2vw;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--darkBlue);
  margin: 0;
  padding: 0;
  font-family: var(--monospace);
}
