.App {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.App-progress {
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 0.5rem;
  left: 0;
  position: absolute;
  right: 0;
}

.App-toggleMuteButton {
  background: none;
  border: none;
  color: var(--mediumGray);
  cursor: pointer;
  display: flex;
  font-size: 4rem;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.2s ease-in-out;
}

.App-toggleMuteButton:hover {
  opacity: 1;
}

.App-toggleMuteButton.is-muted {
  color: var(--red);
}
